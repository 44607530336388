import styled from 'styled-components';
import { useRecordContext } from 'react-admin';
import React, { useEffect, useRef } from 'react';
import { ImageViewer } from './image-viewer';

const ImageCropperWrapper = styled.div`
  background: white;
  padding: 16px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
`;

export const ImageViewerExpand = () => {
  const record = useRecordContext();
  const cropperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cropperRef.current) {
      cropperRef.current.focus();
    }
  }, []);

  if (!record) return null;

  return (
    <ImageCropperWrapper ref={cropperRef}>
      <ImageViewer imageUrl={record.documentPhotoUrl} />
    </ImageCropperWrapper>
  );
};
