import { BooleanInput, Filter } from 'react-admin';
import React from 'react';

export const AgreementsFilters = (props: any) => {
  return (
    <Filter {...props}>
      <BooleanInput source="isDeleted" label={'Удаленные'} alwaysOn />
    </Filter>
  );
};
