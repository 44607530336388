import {
  Button,
  Confirm,
  DateField,
  FunctionField,
  useDelete,
  useNotify,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LABELS } from '../../../constants';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ResourceNames } from '../../../types';
import EditIcon from '@mui/icons-material/Edit';

const StyledButton = styled(Button)`
  .MuiButton-startIcon {
    margin: 0;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  column-gap: 60px;
`;

export const AgreementsDeleteColumn = ({ resourceName }: { resourceName: ResourceNames }) => {
  const [open, setOpen] = useState(false);
  const redirect = useRedirect();
  const record = useRecordContext<any>();
  const notify = useNotify();
  const [deleteOne, { isLoading }] = useDelete();

  const handleDelete = async (id: string, record: any) => {
    try {
      await deleteOne(resourceName, { id, previousData: record });
    } catch (error) {
      notify('Что-то пошло не так...', { type: 'error' });
    }
  };
  if (!record) return null;

  return !record.deletedAt ? (
    <Box display="flex" justifyContent="center">
      {/* Other fields */}
      <FunctionField
        label={''}
        render={record => (
          <StyledButton onClick={() => redirect(`${record.id}`)} color="secondary">
            <EditIcon />
          </StyledButton>
        )}
      />
      <StyledButton onClick={() => setOpen(true)} color="secondary" disabled={!!record.deletedAt}>
        <CloseIcon />
      </StyledButton>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Подтверждение удаления"
        content="Вы уверены, что хотите удалить эту запись?"
        onConfirm={() => handleDelete(record.id, record)}
        onClose={() => setOpen(false)}
      />
    </Box>
  ) : (
    <StyledContainer>
      <DateField source="deletedAt" label={LABELS.deletedAt} sortable={true} />
      <FunctionField
        label={''}
        render={record => (
          <StyledButton onClick={() => redirect(`${record.id}`)} color="secondary">
            <EditIcon />
          </StyledButton>
        )}
      />
    </StyledContainer>
  );
};
