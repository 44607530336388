export enum ResourceNames {
  users = 'users',
  devices = 'devices',
  models = 'models',
  otaDevices = 'ota/devices',
  cartridges = 'cartridges',
  hardwares = 'hardwares',
  firmwares = 'firmwares',
  admins = 'admins',
  puffs = 'puffs',
  featureToggles = 'feature-toggle',
  warrantiesMetaLite = 'warranties/meta-lite',
  warrantiesMetaSmart = 'warranties/meta-smart',
  flavorsTestAgreements = 'agreements/flavors-test',
  metaBetaAgreements = 'agreements/meta-beta',
}

export enum ResourceLabels {
  users = 'Пользователи',
  devices = 'Устройства',
  models = 'Модели',
  otaDevices = 'OTA устройства',
  cartridges = 'Картриджи',
  hardwares = 'Hardwares',
  firmwares = 'Прошивки',
  admins = 'Админы',
  puffs = 'Затяжки',
  featureToggles = 'Управление фичами',
  warrantiesMetaLite = 'Гарантии Meta Lite',
  warrantiesMetaSmart = 'Гарантии Meta Smart',
  flavorsTestAgreements = 'Согл. тестирования вкусов',
  metaBetaAgreements = 'Согл. бета тестирования Meta',
}

export enum ResourceCreateLabels {
  users = 'Создать Пользователя',
  devices = 'Создать устройство',
  models = 'Создать модель',
  cartridges = 'Создать картридж',
  hardwares = 'Создать hardware',
  firmwares = 'Создать прошивку',
  admins = 'Создать админа',
  puffs = 'Создать затяжку',
}

export enum ResourceEditLabels {
  users = 'Редактировать Пользователя',
  devices = 'Редактировать устройство',
  models = 'Редактировать модель',
  cartridges = 'Редактировать картридж',
  hardwares = 'Редактировать hardware',
  firmwares = 'Редактировать прошивку',
  admins = 'Редактировать админа',
  puffs = 'Редактировать затяжку',
  featureToggles = 'Редактировать feature Toggle',
  metaBetaAgreements = 'Редактировать Согл. бета тестирования Meta',
  flavorsTestAgreements = 'Редактировать Согл. тестирования вкусов',
}

export enum ResourceShowLabels {
  users = 'Пользователь',
  devices = 'Устройство',
  models = 'Модель',
  cartridges = 'Картридж',
  hardwares = 'Hardware',
  firmwares = 'Прошивка',
  admins = 'Админ',
  puffs = 'Затяжка',
}
