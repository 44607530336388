/* eslint-disable react/jsx-key */
import React from 'react';
import { Resource } from 'react-admin';
import {
  ResourceCreateLabels,
  ResourceEditLabels,
  ResourceLabels,
  ResourceNames,
  ResourceShowLabels,
} from 'shared/types';
import { DeviceList } from 'shared/widgets/devices/list';
import { DeviceShow } from 'shared/widgets/devices/show';
import { FirmwareList } from 'shared/widgets/firmwares/list';
import { FirmwareShow } from 'shared/widgets/firmwares/show';
import { HardwareList } from 'shared/widgets/hardwares/list';
import { HardwareShow } from 'shared/widgets/hardwares/show';
import { UserList } from 'shared/widgets/users/list';
import { UserShow } from 'shared/widgets/users/show';
import { CartrigdesList } from 'shared/widgets/cartridges/list';
import { CartridgeShow } from 'shared/widgets/cartridges/show';
import { ModelsShow } from 'shared/widgets/models/show';
import { ModelsList } from 'shared/widgets/models/list';
import { UserCreate } from 'shared/widgets/users/create';
import { UserEdit } from 'shared/widgets/users/edit';
import { ModelCreate } from 'shared/widgets/models/create';
import { ModelEdit } from 'shared/widgets/models/edit';
import { CartridgeEdit } from 'shared/widgets/cartridges/edit';
import { FirmwareCreate } from 'shared/widgets/firmwares/create';
import { CartridgeCreate } from 'shared/widgets/cartridges/create';
import { HardwareEdit } from 'shared/widgets/hardwares/edit';
import { HardwareCreate } from 'shared/widgets/hardwares/create';
import { DeviceEdit } from 'shared/widgets/devices/edit';
import { DeviceCreate } from 'shared/widgets/devices/create';
import { FirmwareEdit } from 'shared/widgets/firmwares/edit';
import { Permissions, Roles } from '../types';
import { hasPermission } from '../shared/validators';
import { FeatureToggleList } from '../shared/widgets/feature-toggle/list';
import { FeatureToggleEdit } from '../shared/widgets/feature-toggle/edit';
import { OtaDevicesList } from '../shared/widgets/ota-devices/list';
import { WarrantiesList } from '../shared/widgets/warranties/list';
import { MetaBetaAgreementList } from '../shared/widgets/agreements/meta-beta/list';
import { FlavorsTestAgreementsList } from '../shared/widgets/agreements/flavors-test/list';
import { FlavorsTestAgreementEdit } from '../shared/widgets/agreements/flavors-test/edit';
import { MetaBetaAgreementEdit } from '../shared/widgets/agreements/meta-beta/edit';

// Типизация компонентов
type Components = {
  list?: React.ComponentType;
  show?: React.ComponentType;
  create?: React.ComponentType;
  edit?: React.ComponentType;
};

export const resources = (role: Roles) => {
  const resourceWithPermission = (
    name: string,
    labels: {
      label: string;
      createLabel?: string;
      editLabel?: string;
      showLabel?: string;
    },
    components: Components, // Типизируем компоненты
    permissions: { list?: Permissions; show?: Permissions; create?: Permissions; edit?: Permissions },
  ) => {
    return (
      <Resource
        name={name}
        options={{ ...labels }} // Передаем метки в options
        list={permissions.list && hasPermission(role, permissions.list) ? components.list : undefined}
        show={
          permissions.show && hasPermission(role, permissions.show)
            ? props => {
                // Передаем createLabel внутрь компонента Create
                return components.show ? <components.show {...props} title={labels.showLabel || 'Детали'} /> : null;
              }
            : undefined
        }
        create={
          permissions.create && hasPermission(role, permissions.create)
            ? props => {
                // Передаем createLabel внутрь компонента Create
                return components.create ? (
                  <components.create {...props} title={labels.createLabel || 'Создание'} />
                ) : null;
              }
            : undefined
        }
        edit={
          permissions.edit && hasPermission(role, permissions.edit)
            ? props => {
                // Передаем editLabel внутрь компонента Edit
                return components.edit ? (
                  <components.edit {...props} title={labels.editLabel || 'Редактирование'} />
                ) : null;
              }
            : undefined
        }
      />
    );
  };

  return [
    resourceWithPermission(
      ResourceNames.users,
      {
        label: ResourceLabels.users,
        createLabel: ResourceCreateLabels.users,
        editLabel: ResourceEditLabels.users,
        showLabel: ResourceShowLabels.users,
      },
      {
        list: UserList,
        show: UserShow,
        create: UserCreate,
        edit: UserEdit,
      },
      {
        list: Permissions.ReadUsers,
        show: Permissions.ReadUsers,
        create: Permissions.CreateUsers,
        edit: Permissions.EditUsers,
      },
    ),

    resourceWithPermission(
      ResourceNames.devices,
      {
        label: ResourceLabels.devices,
        createLabel: ResourceCreateLabels.devices,
        editLabel: ResourceEditLabels.devices,
        showLabel: ResourceShowLabels.devices,
      },
      {
        list: DeviceList,
        show: DeviceShow,
        create: DeviceCreate,
        edit: DeviceEdit,
      },
      {
        list: Permissions.ReadDevices,
        show: Permissions.ReadDevices,
        create: Permissions.CreateDevices,
        edit: Permissions.EditDevices,
      },
    ),

    resourceWithPermission(
      ResourceNames.firmwares,
      {
        label: ResourceLabels.firmwares,
        createLabel: ResourceCreateLabels.firmwares,
        editLabel: ResourceEditLabels.firmwares,
        showLabel: ResourceShowLabels.firmwares,
      },
      {
        list: FirmwareList,
        show: FirmwareShow,
        create: FirmwareCreate,
        edit: FirmwareEdit,
      },
      {
        list: Permissions.ReadFirmwares,
        show: Permissions.ReadFirmwares,
        create: Permissions.CreateFirmwares,
        edit: Permissions.EditFirmwares,
      },
    ),

    resourceWithPermission(
      ResourceNames.hardwares,
      {
        label: ResourceLabels.hardwares,
        createLabel: ResourceCreateLabels.hardwares,
        editLabel: ResourceEditLabels.hardwares,
        showLabel: ResourceShowLabels.hardwares,
      },
      {
        list: HardwareList,
        show: HardwareShow,
        create: HardwareCreate,
        edit: HardwareEdit,
      },
      {
        list: Permissions.ReadHardwares,
        show: Permissions.ReadHardwares,
        create: Permissions.CreateHardwares,
        edit: Permissions.EditHardwares,
      },
    ),

    resourceWithPermission(
      ResourceNames.cartridges,
      {
        label: ResourceLabels.cartridges,
        createLabel: ResourceCreateLabels.cartridges,
        editLabel: ResourceEditLabels.cartridges,
        showLabel: ResourceShowLabels.cartridges,
      },
      {
        list: CartrigdesList,
        show: CartridgeShow,
        create: CartridgeCreate,
        edit: CartridgeEdit,
      },
      {
        list: Permissions.ReadCartridges,
        show: Permissions.ReadCartridges,
        create: Permissions.CreateCartridges,
        edit: Permissions.EditCartridges,
      },
    ),

    resourceWithPermission(
      ResourceNames.models,
      {
        label: ResourceLabels.models,
        createLabel: ResourceCreateLabels.models,
        editLabel: ResourceEditLabels.models,
        showLabel: ResourceShowLabels.models,
      },
      {
        list: ModelsList,
        show: ModelsShow,
        create: ModelCreate,
        edit: ModelEdit,
      },
      {
        list: Permissions.ReadModels,
        show: Permissions.ReadModels,
        create: Permissions.CreateModels,
        edit: Permissions.EditModels,
      },
    ),

    resourceWithPermission(
      ResourceNames.otaDevices,
      {
        label: ResourceLabels.otaDevices,
      },
      {
        list: OtaDevicesList,
      },
      {
        list: Permissions.ReadOtaDevices,
      },
    ),
    resourceWithPermission(
      ResourceNames.flavorsTestAgreements,
      {
        label: ResourceLabels.flavorsTestAgreements,
        editLabel: ResourceEditLabels.flavorsTestAgreements,
      },
      {
        list: FlavorsTestAgreementsList,
        edit: FlavorsTestAgreementEdit,
      },
      {
        list: Permissions.ReadAgreements,
        edit: Permissions.ReadAgreements,
      },
    ),
    resourceWithPermission(
      ResourceNames.metaBetaAgreements,
      {
        label: ResourceLabels.metaBetaAgreements,
        editLabel: ResourceEditLabels.metaBetaAgreements,
      },
      {
        list: MetaBetaAgreementList,
        edit: MetaBetaAgreementEdit,
      },
      {
        list: Permissions.ReadAgreements,
        edit: Permissions.ReadAgreements,
      },
    ),
    resourceWithPermission(
      ResourceNames.warrantiesMetaLite,
      {
        label: ResourceLabels.warrantiesMetaLite,
      },
      {
        list: WarrantiesList,
      },
      {
        list: Permissions.ReadWarranties,
      },
    ),

    resourceWithPermission(
      ResourceNames.warrantiesMetaSmart,
      {
        label: ResourceLabels.warrantiesMetaSmart,
      },
      {
        list: WarrantiesList,
      },
      {
        list: Permissions.ReadWarranties,
      },
    ),

    resourceWithPermission(
      ResourceNames.featureToggles,
      {
        label: ResourceLabels.featureToggles,
        editLabel: ResourceEditLabels.featureToggles,
      },
      {
        list: FeatureToggleList,
        edit: FeatureToggleEdit,
      },
      {
        list: Permissions.ReadFeatureToggles,
        edit: Permissions.EditFeatureToggles,
      },
    ),
  ];
};
