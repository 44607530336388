import { useMemo } from 'react';
import {
  BooleanInput,
  Edit,
  FileField,
  FileInput,
  FormDataConsumer,
  Loading,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetOne,
  useNotify,
  useRedirect,
} from 'react-admin';
import { AdminHardwareResponseDto } from 'shared/api';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { LABELS } from 'shared/constants/labels';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import { httpClient } from 'shared/services/http-client';
import { deleteFile } from 'shared/utils/files/delete-files';
import { moveFile } from 'shared/utils/files/move-files';
import { FirmwareEditToolbar } from './ui/custom-toolbar';
import { RichTextInput } from 'ra-input-rich-text';
import { isFirmwareVersion, isRequired } from '../../../validators';
import { uploadFile } from '../../../utils/files/upload-files';
import { getFileMeta } from '../../../utils/files/get-file-meta';
import { useParams } from 'react-router-dom';
import { getFileKeyFromUrl } from '../../../utils/get-file-key';
import { getSignedUrl } from '../../../utils/files/get-signed-url';
import { ResourceNames } from '../../../types';

export const FirmwareEdit = (props: { title?: string }) => {
  const { id } = useParams();
  const notify = useNotify();
  const redirect = useRedirect();
  const { data, isLoading } = useGetOne(ResourceNames.firmwares, { id });

  const { data: hardwares, isLoading: isLoadingHardwares } = useHttpClient<AdminHardwareResponseDto>(
    `${API_URL.getHardwares}?modelId=${data?.hardware?.model?.id}`,
    { skip: isLoading },
  );

  // Здесь вызываем useMemo
  const hardwareVersionOptions = useMemo(
    () =>
      hardwares?.data.map((hardware: any) => ({
        id: hardware.id,
        version: `${hardware.version}, ${hardware.model.name}`,
      })) || [],
    [hardwares],
  );

  const handleSubmit = async (data: any) => {
    try {
      let downloadFileKeyUrl = data.downloadFileKey;
      const fileMeta = await getFileMeta(downloadFileKeyUrl);

      // Check if the old file exists
      if (!fileMeta) {
        if (!data.newFileKey) {
          notify('Старый файл не существует, добавьте новый', { type: 'error' });
          return;
        }
        notify('Старый файл не существует, создается новый', { type: 'warning' });
      }

      // Handle deletion if a new file is provided
      if (fileMeta && data.newFileKey) {
        const isDeleted = await deleteFile(data.id);
        if (!isDeleted) {
          notify('Не удалось удалить старый файл, продолжается загрузка нового файла', { type: 'warning' });
        }
      }

      // Upload or move the file based on `newFileKey`
      if (data.newFileKey) {
        const uploadedFile = await uploadFile(data.newFileKey.rawFile, data.hardware.id, data.version);
        const fileKey = getFileKeyFromUrl(uploadedFile.fileKey);

        if (fileKey) {
          downloadFileKeyUrl = await getSignedUrl(fileKey);
        } else {
          notify('Ошибка при загрузке нового файла', { type: 'error' });
          return;
        }
      } else if (fileMeta) {
        const movedFile = await moveFile(data.id, data.hardware.id, data.version);
        const fileKey = getFileKeyFromUrl(movedFile.fileKey);
        if (fileKey) {
          downloadFileKeyUrl = await getSignedUrl(fileKey);
        } else {
          notify('Ошибка при перемещении файла', { type: 'error' });
          return;
        }
      }

      await httpClient(`${API_BASE_URL_ADMIN}${API_URL.updateFirmware(data.id)}`, {
        method: 'PATCH',
        body: JSON.stringify({
          hardwareId: data.hardware.id,
          version: data.version,
          releaseNotes: data.releaseNotes,
          downloadFileKey: downloadFileKeyUrl,
          notifyOtaUsers: data.notifyOtaUsers,
          isDeleted: Boolean(data.deletedAt),
        }),
      });

      notify('Прошивка  обновлена', { type: 'success' });
      redirect('/firmwares');
    } catch (err: any) {
      notify(err.message, { type: 'error' });
    }
  };

  if (isLoadingHardwares) return <Loading />;

  const pageTitle = `${props.title} ${data?.version}`;

  return (
    <Edit {...props} title={pageTitle} mutationOptions={{}}>
      <SimpleForm onSubmit={handleSubmit} toolbar={<FirmwareEditToolbar />}>
        <TextInput source="version" validate={[isRequired, isFirmwareVersion]} />
        <SelectInput
          source="hardware.id"
          label={LABELS.hardwareId}
          inputProps={{ maxLength: 127 }}
          helperText="Уникальное, не более 127 знаков"
          choices={hardwareVersionOptions}
          optionText="version"
          fullWidth
          validate={[isRequired]}
        />
        <RichTextInput source="releaseNotes" label={'Release Notes'} helperText="Уникальное, не более 526 знаков" />
        <FileInput
          label={LABELS.downloadFileKey}
          source="newFileKey"
          accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed">
          <FileField source="src" title="title" />
        </FileInput>
        <FormDataConsumer<{ deletedAt: boolean }>>
          {({ formData }) => (
            <BooleanInput
              source="notifyOtaUsers"
              label={LABELS.notifyOtaUsers}
              helperText="Отправить уведомление OTA пользователям о доступности этой версии прошивки."
              disabled={formData.deletedAt}
            />
          )}
        </FormDataConsumer>
        <BooleanInput source="deletedAt" label={LABELS.isDeleted} helperText="С возможностью восстановить" />
      </SimpleForm>
    </Edit>
  );
};
