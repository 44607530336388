import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  MenuItem,
  Select,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  OutlinedInput,
  SelectChangeEvent,
  TextField,
  Button,
} from '@mui/material';
import { ExportButton, useResourceContext } from 'react-admin';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import { useHttpClient } from '../../../../hooks/useHttpClient';
import { API_URL } from '../../../../constants';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { DatePopper } from './date-popper';
import { ResourceNames } from '../../../../types';

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px 24px;
  border-radius: 8px;
`;

const styledButtonCSS = css`
  display: flex;
  background-color: #ffffff;
  padding: 16px 20px;
  border-radius: 8px;
  text-transform: none;
  height: 42px;
  color: #4f4f4f;
  font-size: 14px;
`;

const StyledExportButton = styled(ExportButton)`
  ${styledButtonCSS}
`;

const StyledButton = styled(Button)`
  border-color: #ffffff;

  &:hover {
    border-color: #eef3f7;
  }

  ${styledButtonCSS}
`;

const controlCSS = css`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    min-width: 150px;
    background-color: #ffffff;
    border-radius: 8px;
    margin: 0;
  }

  .MuiInputBase-root {
    width: 100%;
    & .MuiOutlinedInput-notchedOutline {
      border-color: #ffffff;
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #ffffff;
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 8px;
    background-color: #ffffff;
    padding-right: 8px;
    height: 42px;
    border-color: #ffffff;
  }

  .MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75); /* Уменьшение метки при фокусе */
  }

  .MuiSelect-icon {
    color: #4f4f4f; /* Цвет стрелки выпадающего списка */
  }
`;

const StyledFormControl = styled(FormControl)`
  ${controlCSS}
`;

const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 150px;
  background-color: #ffffff; /* Белый фон */
  border-radius: 8px; /* Скругленные углы */
  height: 42px; /* Фиксированная высота */
  padding: 16px 20px; /* Внутренний отступ */
  gap: 10px;
  .MuiButtonBase-root {
    padding: 0;
    &.MuiSvgIcon-root {
      width: 18px;
    }
  }
`;

const StyledDatePicker = styled(DatePicker<Date>)`
  margin: 0;
  ${controlCSS}

  .MuiButtonBase-root {
    margin-right: 0;
  }
`;

const StyledCheckboxLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;
`;

enum WarrantyStatusLabel {
  active = 'Активна',
  inactive = 'Не активна',
}

export const Filters = ({ onApply, onReset }: { onApply: (filters: any) => void; onReset: () => void }) => {
  const resource = useResourceContext();

  const apiMapper = {
    [ResourceNames.warrantiesMetaSmart]: API_URL.getMetaSmartWarranties,
    [ResourceNames.warrantiesMetaLite]: API_URL.getMetaLiteWarranties,
  };

  const { data } = useHttpClient<{ availableCitiesFilter: string[] }>(apiMapper[resource]);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedWarrantyStatus, setSelectedWarrantyStatus] = useState<string[]>([]);
  const [isTelegramId, setTelegramId] = useState<boolean>(false);
  const [withDeleted, setWithDeleted] = useState<boolean>(false);
  const [purchaseDateFrom, setPurchaseDateFrom] = useState<Date | null>(null);
  const [purchaseDateTo, setPurchaseDateTo] = useState<Date | null>(null);
  const [russianCities, setRussianCities] = useState<string[]>([]);

  useEffect(() => {
    if (data) {
      setRussianCities(data.availableCitiesFilter);
    }
  }, [data]);

  // Обновляем фильтры после каждого изменения
  useEffect(() => {
    const filters = {
      ...(selectedWarrantyStatus.length
        ? { warrantyStatus: selectedWarrantyStatus[0] }
        : { warrantyStatus: undefined }),
      ...(isTelegramId ? { isTelegramId: true } : { isTelegramId: undefined }),
      ...(withDeleted ? { isDeleted: true } : { isDeleted: false }),
      ...(purchaseDateFrom ? { purchaseDateFrom } : { purchaseDateFrom: undefined }),
      ...(purchaseDateTo ? { purchaseDateTo } : { purchaseDateTo: undefined }),
      ...(selectedCities.length ? { cities: selectedCities } : { cities: undefined }),
    };

    const timer = setTimeout(() => {
      onApply(filters);
    }, 300);

    return () => clearTimeout(timer);
  }, [selectedCities, selectedWarrantyStatus, isTelegramId, purchaseDateFrom, purchaseDateTo, withDeleted]);

  const handleCityChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedCities(event.target.value as string[]);
  };

  const handleWarrantyStatusChange = (event: any) => {
    setSelectedWarrantyStatus(event.target.value as string[]);
  };

  const handleTelegramIdChange = () => {
    setTelegramId(!isTelegramId);
  };

  const handleWithDeletedChange = () => {
    setWithDeleted(!withDeleted);
  };

  const handleResetFilters = () => {
    setSelectedCities([]);
    setSelectedWarrantyStatus([]);
    setTelegramId(false);
    setPurchaseDateFrom(null);
    setPurchaseDateTo(null);
    setWithDeleted(false);
    onReset();
  };

  const handleApplyPurchaseDateRangeChange = (filters: {
    purchaseDateFrom?: Date | null;
    purchaseDateTo?: Date | null;
  }) => {
    if (filters.purchaseDateTo) {
      // Преобразуем дату в UTC (устанавливаем время на 00:00:00 в UTC)
      const utcDate = new Date(
        Date.UTC(
          filters.purchaseDateTo.getUTCFullYear(),
          filters.purchaseDateTo.getUTCMonth(),
          filters.purchaseDateTo.getUTCDate(),
        ),
      );
      setPurchaseDateTo(utcDate);
    } else {
      setPurchaseDateTo(null);
    }
    if (filters.purchaseDateFrom) {
      // Преобразуем дату в UTC (устанавливаем время на 00:00:00 в UTC)
      const utcDate = new Date(
        Date.UTC(
          filters.purchaseDateFrom.getUTCFullYear(),
          filters.purchaseDateFrom.getUTCMonth(),
          filters.purchaseDateFrom.getUTCDate(),
        ),
      );
      setPurchaseDateFrom(utcDate);
    } else {
      setPurchaseDateFrom(null);
    }
  };

  const handleResetPurchaseDateRange = () => {
    setPurchaseDateFrom(null);
    setPurchaseDateTo(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <FiltersWrapper>
        {/* Telegram ID Checkbox */}
        <StyledCheckboxWrapper>
          <Checkbox
            checked={isTelegramId}
            onChange={handleTelegramIdChange}
            style={{
              color: isTelegramId ? '#2195f1' : '#4f4f4f', // Цвет активного/неактивного состояния
            }}
          />
          <StyledCheckboxLabel>Telegram ID</StyledCheckboxLabel>
        </StyledCheckboxWrapper>
        <StyledFormControl variant="outlined">
          <InputLabel>Статус гарантии</InputLabel>
          <Select
            multiple
            value={selectedWarrantyStatus}
            onChange={handleWarrantyStatusChange}
            input={<OutlinedInput label="Статус гарантии" />}
            renderValue={selected => (selected as string[]).map(status => WarrantyStatusLabel[status]).join(', ')}>
            {Object.keys(WarrantyStatusLabel).map(status => (
              <MenuItem
                key={status}
                value={status}
                disabled={selectedWarrantyStatus.length === 1 && !selectedWarrantyStatus.includes(status)}>
                <Checkbox checked={selectedWarrantyStatus.includes(status)} />
                <ListItemText primary={WarrantyStatusLabel[status]} />
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <StyledFormControl variant="outlined">
          <InputLabel>Города</InputLabel>
          <Select
            multiple
            value={selectedCities}
            onChange={handleCityChange}
            input={<OutlinedInput label="Города" />}
            renderValue={selected => (selected as string[]).join(', ')}>
            {russianCities.map(city => (
              <MenuItem key={city} value={city}>
                <Checkbox checked={selectedCities.includes(city)} />
                <ListItemText primary={city} />
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <DatePopper
          onApply={handleApplyPurchaseDateRangeChange}
          onReset={handleResetPurchaseDateRange}
          valueFrom={purchaseDateFrom}
          valueTo={purchaseDateTo}
        />
        <StyledCheckboxWrapper>
          <Checkbox
            checked={withDeleted}
            onChange={handleWithDeletedChange}
            style={{
              color: withDeleted ? '#2195f1' : '#4f4f4f', // Цвет активного/неактивного состояния
            }}
          />
          <StyledCheckboxLabel>Удаленные</StyledCheckboxLabel>
        </StyledCheckboxWrapper>
        <StyledButton variant="outlined" startIcon={<CleaningServicesIcon />} onClick={handleResetFilters}>
          Очистить
        </StyledButton>
        <StyledExportButton label={'Экспорт'} />
      </FiltersWrapper>
    </LocalizationProvider>
  );
};
