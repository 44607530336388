import { BooleanInput, Edit, Loading, SimpleForm, TextInput, useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';
import { LABELS } from 'shared/constants/labels';
import { ResourceNames } from '../../../types';

export const DeviceEdit = (props: { title?: string }) => {
  const { id } = useParams();
  const { data, isLoading } = useGetOne(ResourceNames.devices, { id });

  const isDeleted = Boolean(data?.deletedAt);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  const deviceName = data?.name;

  return (
    <Edit {...props} title={`${props.title} ${deviceName}`}>
      <SimpleForm defaultValues={{ isDeleted }}>
        <TextInput source="name" />
        <TextInput source="alias" />
        <TextInput source="mac" />
        <TextInput source="serialNumber" />
        <TextInput source="hardware.id" />
        <TextInput source="firmware.id" />
        <TextInput source="user.id" />
        <BooleanInput source="isDeleted" label={LABELS.isDeleted} helperText="С возможностью восстановить" />
      </SimpleForm>
    </Edit>
  );
};
