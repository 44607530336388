import { Loading, Show, useGetOne, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { DeviceShowContent } from './ui/content';

export const DeviceShow = (props: { title?: string }) => {
  const { id } = useParams();

  const redirect = useRedirect();
  const { data, isLoading } = useGetOne('devices', { id: id as string }, { onError: () => redirect('/devices') });

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  const deviceName = data?.name;

  return (
    <Show {...props} title={`${props.title} ${deviceName}`}>
      <DeviceShowContent data={data} />
    </Show>
  );
};
