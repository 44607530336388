import { DateField, Loading, Show, SimpleShowLayout, TextField, useGetOne } from 'react-admin';

import { LABELS } from 'shared/constants/labels';
import { useParams } from 'react-router-dom';
import { ResourceNames } from '../../../types';

export const ModelsShow = (props: { title?: string }) => {
  const { id } = useParams();
  const { data: model, isLoading } = useGetOne(ResourceNames.models, {
    id,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!model) return null;

  return (
    <Show {...props} title={`${props.title} ${model.name}`}>
      <SimpleShowLayout>
        <TextField source="id" label={LABELS.id} sortable={false} />
        <TextField source="name" label={LABELS.name} />
        <DateField source="createdAt" label={LABELS.addedAt} />
        <DateField source="deletedAt" label={LABELS.deletedAt} />
      </SimpleShowLayout>
    </Show>
  );
};
