import { BooleanInput, Create, DateInput, SelectInput, SimpleForm, TextInput, useNotify } from 'react-admin';
import { PhoneInput } from 'shared/components';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';

import { LABELS } from 'shared/constants/labels';
import { httpClient } from 'shared/services/http-client';
import { dateParser } from 'shared/utils/dateFormatters';
import { getValidDatesRange } from 'shared/utils/getValidDatesRange';
import { isNameValid, isRequired, isPasswordValid } from 'shared/validators';
import { rolesOptions } from '../../../constants';
export const UserCreate = (props: { title?: string }) => {
  const datesLimitRange = getValidDatesRange();
  const notify = useNotify();

  const handleSubmit = async (data: any) => {
    try {
      await httpClient(`${API_BASE_URL_ADMIN}${API_URL.createUsers}`, {
        method: 'POST',
        body: JSON.stringify({
          ...data,
        }),
      });

      notify('Пользователь добавлен', { type: 'success' });
    } catch (error: any) {
      if (error.message) {
        notify(
          `Что-то пошло не так: ${error?.message}
        `,
          { type: 'error' },
        );
      } else {
        notify('Что-то пошло не так...', { type: 'error' });
      }
    }
  };

  return (
    <Create {...props}>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput
          source="username"
          validate={[isRequired, isNameValid]}
          label={LABELS.username}
          helperText="Без пробелов и спецсимволов, до 20 знаков"
        />
        <PhoneInput />

        <DateInput
          source="dateOfBirth"
          parse={dateParser}
          validate={[isRequired]}
          label={LABELS.dateOfBirth}
          inputProps={datesLimitRange}
        />

        <SelectInput choices={rolesOptions} source="role" label={LABELS.userRole} validate={[isRequired]} fullWidth />

        <BooleanInput source="isPhoneConfirmed" label={LABELS.isPhoneConfirmed} />

        <TextInput source="password" validate={[isRequired, isPasswordValid]} label={LABELS.password} />
        <p>
          Хороший пароль включает в себя:
          <br />- Длину больше 8 символов
          <br />- Длину менее 32 символов
          <br />- Прописные и заглавные буквы
          <br />- Цифры
          <br />- Специальные знаки
        </p>
      </SimpleForm>
    </Create>
  );
};
