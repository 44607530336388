import React from 'react';
import { BooleanField, Datagrid, DateField, FunctionField, List, NumberField, TextField, UrlField } from 'react-admin';
import { LABELS } from '../../../../constants';
import {
  AgreementsDeleteColumn,
  AgreementsDeleteLabel,
  AgreementsFilters,
  AgreementsToolbar,
  ImageViewerExpand,
} from '../../components';
import styled from 'styled-components';
import { ResourceNames } from '../../../../types';

const StyledList = styled(List)`
  .RaFilter-form {
    justify-content: flex-end;
    padding-bottom: 8px;
  }
`;

const StyledUrlField = styled(UrlField)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
  display: block;
`;

export const FlavorsTestAgreementsList = (props: { title?: string }) => {
  return (
    <StyledList
      {...props}
      actions={<AgreementsToolbar />}
      filters={<AgreementsFilters />}
      filterDefaultValues={{ sort: 'createdAt', order: 'ASC', isDeleted: false }}>
      <Datagrid bulkActionButtons={false} expand={<ImageViewerExpand />}>
        <NumberField source="iterator" label={LABELS.iterator} sortable={false} />
        <TextField source="phone" label={LABELS.phone} sortable={false} />
        <TextField source="fullName" label={LABELS.fullName} sortable={false} />
        <DateField source="birthDate" label={LABELS.dateOfBirth} sortable={false} />
        <TextField source="passportSeriesAndNumber" label={LABELS.passportSeriesAndNumber} sortable={false} />
        <TextField source="registrationAddress" label={LABELS.registrationAddress} sortable={false} />
        <TextField source="issuedBy" label={LABELS.issuedBy} sortable={false} />
        <DateField source="issuedDate" label={LABELS.issuedDate} sortable={false} />
        <BooleanField source="nonDisclosureAgreement" label={LABELS.nonDisclosureAgreement} sortable={false} />
        <BooleanField
          source="dataProcessingAndPrivacyConsent"
          label={LABELS.dataProcessingAndPrivacyConsent}
          sortable={false}
        />
        {/* Используем кастомный компонент */}
        <StyledUrlField source="documentPhotoUrl" label={LABELS.documentPhotoUrl} target="_blank" />
        <DateField source="createdAt" label={LABELS.addedAt} sortable={true} />
        <FunctionField
          label={<AgreementsDeleteLabel />}
          render={() => <AgreementsDeleteColumn resourceName={ResourceNames.flavorsTestAgreements} />}
        />
      </Datagrid>
    </StyledList>
  );
};
