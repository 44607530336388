import { Create, SimpleForm, TextInput, useNotify } from 'react-admin';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';

import { LABELS } from 'shared/constants/labels';
import { httpClient } from 'shared/services/http-client';

import { isRequired } from 'shared/validators';

export const ModelCreate = (props: { title?: string }) => {
  const notify = useNotify();

  const handleSubmit = async (data: any) => {
    try {
      await httpClient(`${API_BASE_URL_ADMIN}${API_URL.createModels}`, {
        method: 'POST',
        body: JSON.stringify({
          ...data,
        }),
      });

      notify('Модель добавлена', { type: 'success' });
    } catch (error: any) {
      if (error.message) {
        notify(
          `Что-то пошло не так: ${error?.message}
        `,
          { type: 'error' },
        );
      } else {
        notify('Что-то пошло не так...', { type: 'error' });
      }
    }
  };
  return (
    <Create {...props}>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput
          source="name"
          validate={[isRequired]}
          label={LABELS.modelName}
          inputProps={{ maxLength: 127 }}
          helperText="Уникальное, не более 127 знаков"
        />
      </SimpleForm>
    </Create>
  );
};
