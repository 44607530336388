/* eslint-disable react/jsx-key */
import { Datagrid, List, ShowButton, TextField, DateField, usePermissions } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { HardwareListActions } from './ui/actions';
import { Permissions, Roles } from '../../../../types';
import { hasPermission } from '../../../validators';

export const HardwareList = (props: { title?: string }) => {
  const { permissions } = usePermissions();
  const role = permissions as Roles;

  return (
    <List
      {...props}
      actions={<HardwareListActions isCreateDisabled={!hasPermission(role, Permissions.CreateHardwares)} />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label={LABELS.hardwareId} sortable={false} />
        <TextField source="version" label={LABELS.version} sortable={true} />
        <TextField source="model.name" label={LABELS.model} sortable={false} />

        <DateField source="createdAt" label={LABELS.addedAt} sortable={true} />
        <DateField source="deletedAt" label={LABELS.deletedAt} sortable={true} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
