import React from 'react';
import { useListContext } from 'react-admin';

import { LABELS } from '../../../constants';

export const AgreementsDeleteLabel = () => {
  const { filterValues } = useListContext();
  const isDeleted = filterValues.isDeleted;

  if (!isDeleted) {
    return <></>;
  }

  return <span>{LABELS.deletedAt}</span>;
};
