import { regex } from 'react-admin';

export const isPhoneValid = regex(/^\+7\s?9[0-9]{2}\s?[0-9]{3}\s?[0-9]{2}\s?[0-9]{2}$/, 'Некорректный номер телефона');
export const isPassportSeriesAndNumberValid = regex(
  /^\d{4} \d{6}$/,
  'Серия и номер паспорта должны быть действительными',
);

export const validateImage = (value: any) => {
  const validTypes = ['image/jpeg', 'image/png'];
  if (value?.rawFile && !validTypes.includes(value.rawFile.type)) {
    return 'Поддерживаются только изображения JPG или PNG';
  }
  return undefined;
};
