import {
  BooleanInput,
  DateInput,
  Edit,
  ImageField,
  ImageInput,
  Loading,
  SimpleForm,
  TextInput,
  useGetOne,
  useNotify,
  useRedirect,
} from 'react-admin';
import { API_URL, LABELS } from '../../../../constants';
import { useParams } from 'react-router-dom';
import { ResourceNames } from '../../../../types';
import { isRequired } from '../../../../validators';
import { isPassportSeriesAndNumberValid, isPhoneValid, validateImage } from '../../validators';
import styled from 'styled-components';
import { httpClient } from '../../../../services/http-client';
import { API_BASE_URL_ADMIN } from '../../../../config';
import { CustomEditToolbar } from '../../components';

const StyledImageField = styled(ImageField)`
  .RaImageField-image {
    width: 250px;
    height: 250px;
  }
`;

export const FlavorsTestAgreementEdit = (props: { title?: string }) => {
  const { id } = useParams();
  const notify = useNotify();
  const redirect = useRedirect();
  const { data, isLoading } = useGetOne(ResourceNames.flavorsTestAgreements, { id });

  const isDeleted = Boolean(data?.deletedAt);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  const phone = data?.phone;

  const handleSubmit = async (data: any) => {
    try {
      const formData = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          formData.append(key, value.toString());
        }
      });

      // Добавляем изображение только если оно загружено
      if (data.documentPhoto && data.documentPhoto?.rawFile) {
        formData.append('documentPhoto', data.documentPhoto.rawFile);
      }

      await httpClient(`${API_BASE_URL_ADMIN}${API_URL.updateFlavorTestAgreement(data.id)}`, {
        method: 'PATCH',
        body: formData,
      });

      notify('Соглашение бета тестирования Meta обновлено', { type: 'success' });
      redirect(`/${ResourceNames.flavorsTestAgreements}`);
    } catch (err: any) {
      console.error('Error during update meta beta agreement', err);
      notify(err.message, { type: 'error' });
      redirect(`/${ResourceNames.flavorsTestAgreements}`);
    }
  };
  return (
    <Edit {...props} title={`${props.title} ${phone}`}>
      <SimpleForm defaultValues={{ isDeleted }} onSubmit={handleSubmit} toolbar={<CustomEditToolbar />}>
        <TextInput source="phone" label={LABELS.phone} validate={[isPhoneValid, isRequired]} disabled={isDeleted} />
        <TextInput source="fullName" label={LABELS.fullName} validate={isRequired} disabled={isDeleted} />
        <DateInput source="birthDate" label={LABELS.dateOfBirth} validate={isRequired} disabled={isDeleted} />
        <TextInput
          source="passportSeriesAndNumber"
          label={LABELS.passportSeriesAndNumber}
          validate={[isRequired, isPassportSeriesAndNumberValid]}
          disabled={isDeleted}
        />
        <TextInput
          source="registrationAddress"
          label={LABELS.registrationAddress}
          validate={isRequired}
          disabled={isDeleted}
        />
        <TextInput source="issuedBy" label={LABELS.issuedBy} validate={isRequired} disabled={isDeleted} />
        <DateInput source="issuedDate" label={LABELS.issuedDate} disabled={isDeleted} />
        <BooleanInput
          source="nonDisclosureAgreement"
          label={LABELS.nonDisclosureAgreement}
          validate={isRequired}
          disabled={isDeleted}
        />
        <BooleanInput
          source="dataProcessingAndPrivacyConsent"
          label={LABELS.dataProcessingAndPrivacyConsent}
          validate={isRequired}
          disabled={isDeleted}
        />
        <BooleanInput source="isDeleted" label={LABELS.isDeleted} />
        {/* Поле для редактирования изображения */}
        <ImageInput
          source="documentPhoto"
          label={LABELS.documentPhotoUrl}
          accept="image/*"
          validate={validateImage}
          disabled={isDeleted}>
          <StyledImageField source="src" title="title" />
        </ImageInput>

        {/* Показываем текущее изображение, если оно есть */}
        <StyledImageField source="documentPhotoUrl" label="Current Photo" />
      </SimpleForm>
    </Edit>
  );
};
