import { ExportButton, SortButton, TopToolbar, useListContext } from 'react-admin';
import { SORTING_VALUES } from '../../../constants';
import React from 'react';

export const AgreementsToolbar = () => {
  const { filterValues } = useListContext();

  const isDeleted = filterValues.isDeleted;
  const fields = [SORTING_VALUES.createdAt];
  if (isDeleted) {
    fields.push(SORTING_VALUES.deletedAt);
  }
  return (
    <TopToolbar>
      <SortButton fields={fields} />
      <ExportButton />
    </TopToolbar>
  );
};
