/* eslint-disable react/jsx-key */
import { Datagrid, DateField, List, ShowButton, TextField, usePermissions } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { FirmwareListActions } from './ui/actions';
import { Permissions, Roles } from '../../../../types';
import { hasPermission } from '../../../validators';

export const FirmwareList = (props: { title?: string }) => {
  const { permissions } = usePermissions();
  const role = permissions as Roles;

  return (
    <List
      {...props}
      actions={<FirmwareListActions isCreateButtonDisabled={!hasPermission(role, Permissions.CreateFirmwares)} />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label={LABELS.firmwareId} sortable={false} />
        <TextField source="version" label={LABELS.version} sortable={true} />
        <TextField source="hardware.version" label={LABELS.hardware} sortable={false} />
        <TextField source="hardware.model.name" label={LABELS.model} sortable={false} />

        <DateField source="createdAt" label={LABELS.addedAt} sortable={true} />
        <DateField source="deletedAt" label={LABELS.deletedAt} sortable={true} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
