import { DateField, Loading, Show, SimpleShowLayout, TextField, useGetOne } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { useParams } from 'react-router-dom';
import { ResourceNames } from '../../../types';

export const CartridgeShow = (props: { title?: string }) => {
  const { id } = useParams();
  const { data, isLoading } = useGetOne(ResourceNames.cartridges, { id });

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  return (
    <Show {...props} title={`${props.title} ${data.flavor}`}>
      <SimpleShowLayout>
        <TextField source="id" label={LABELS.id} sortable={false} />
        <TextField source="model.name" label={LABELS.model} />
        <TextField source="factoryId" label={LABELS.factoryId} />
        <TextField source="rMin" label={LABELS.rMin} />
        <TextField source="rMax" label={LABELS.rMax} />
        <TextField source="formula" label={LABELS.formula} />
        <TextField source="flavor" label={LABELS.flavor} />
        <TextField source="comment" label={LABELS.comment} />
        <DateField source="createdAt" label={LABELS.addedAt} />
        <DateField source="deletedAt" label={LABELS.deletedAt} />
      </SimpleShowLayout>
    </Show>
  );
};
