import { AuthProvider, UserIdentity } from 'react-admin';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { StorageKeys } from 'types';

export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    const request = new Request(`${API_BASE_URL_ADMIN}/auth/sign-in`, {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then(async response => {
        const result = await response.json();

        if (response.status < 200 || response.status >= 300) {
          throw new Error(result.message);
        }

        return result;
      })
      .then(result => {
        localStorage.setItem(StorageKeys.token, result.token);
        localStorage.setItem(StorageKeys.permissions, result.role);
        localStorage.setItem(StorageKeys.email, result.email);
        localStorage.setItem(StorageKeys.userId, result.id);
      })
      .catch(error => {
        throw new Error(error.message || 'Login failed');
      });
  },
  logout: () => {
    localStorage.removeItem(StorageKeys.token);
    localStorage.removeItem(StorageKeys.permissions);
    localStorage.removeItem(StorageKeys.email);
    localStorage.removeItem(StorageKeys.userId);
    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem(StorageKeys.token) ? Promise.resolve() : Promise.reject()),
  checkError: (error: any) => {
    const status = error?.status;

    if (status === 401 || status === 403) {
      localStorage.removeItem(StorageKeys.token);
      localStorage.removeItem(StorageKeys.permissions);
      localStorage.removeItem(StorageKeys.email);
      localStorage.removeItem(StorageKeys.userId);

      return Promise.reject(); // This will trigger redirection to the login page.
    }

    return Promise.resolve(); // For other errors, we don't log out the user.
  },
  getIdentity: async (): Promise<UserIdentity> => {
    const token = localStorage.getItem(StorageKeys.token);

    try {
      const response = await fetch(`${API_BASE_URL_ADMIN}/auth/profile`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        const errorResult = await response.json().catch(() => ({})); // Handle non-JSON error responses
        console.warn('Failed to fetch profile:', errorResult.message || response.statusText);
        return { id: 'user', fullName: 'John Doe' }; // Return default on error
      }

      // Ensure the response has content before parsing
      const text = await response.text();
      const result = text ? JSON.parse(text) : {}; // Handle empty response

      return { id: result.id ?? 'user', fullName: result.email ?? 'John Doe' };
    } catch (error) {
      console.error('Error fetching profile:', error);
      return { id: 'user', fullName: 'John Doe' }; // Return default on catch
    }
  },

  getPermissions: () => {
    const role = localStorage.getItem(StorageKeys.permissions);
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
