import { BooleanInput, DateInput, Edit, SimpleForm, TextInput, useGetOne, Loading, SelectInput } from 'react-admin';
import { PhoneInput } from 'shared/components';
import { LABELS } from 'shared/constants/labels';
import { dateParser } from 'shared/utils/dateFormatters';
import { getValidDatesRange } from 'shared/utils/getValidDatesRange';
import { isRequired, isPasswordValid, isNameValid } from 'shared/validators';
import { useParams } from 'react-router-dom';
import { UserCustomEditToolbar } from './ui/custom-toolbar';
import { rolesOptions } from '../../../constants';
import { ResourceNames } from '../../../types';

export const UserEdit = (props: { title?: string }) => {
  const datesLimitRage = getValidDatesRange();
  const { id } = useParams();
  const { data: user, isLoading } = useGetOne(ResourceNames.users, { id });
  const isDeleted = Boolean(user?.deletedAt);

  if (isLoading) {
    return <Loading />;
  }

  if (!user) return null;

  const pageTitle = `${user?.phone} ${user?.email || ''}`;

  return (
    <Edit {...props} title={`${props.title} ${pageTitle}`} resource={ResourceNames.users}>
      <>
        <SimpleForm defaultValues={{ isDeleted }} toolbar={<UserCustomEditToolbar />}>
          <TextInput
            source="username"
            validate={[isRequired, isNameValid]}
            label={LABELS.username}
            helperText="Без спецсимволов до 20 знаков"
          />
          <PhoneInput />

          <DateInput
            source="dateOfBirth"
            parse={dateParser}
            validate={[isRequired]}
            label={LABELS.dateOfBirth}
            inputProps={datesLimitRage}
          />
          <BooleanInput source="isPhoneConfirmed" label={LABELS.isPhoneConfirmed} />
          <SelectInput choices={rolesOptions} source="role" label={LABELS.userRole} fullWidth />
          <BooleanInput source="isDeleted" label={LABELS.isDeleted} helperText="С возможностью восстановить" />
          <BooleanInput source="isBanned" label={LABELS.isBanned} />
        </SimpleForm>
        <SimpleForm toolbar={<UserCustomEditToolbar />}>
          <TextInput source="password" validate={[isPasswordValid]} label={LABELS.password} />
          <p>
            Хороший пароль включает в себя:
            <br />- Длину больше 8 символов
            <br />- Длину менее 32 символов
            <br />- Прописные и заглавные буквы
            <br />- Цифры
            <br />- Специальные знаки
          </p>
        </SimpleForm>
      </>
    </Edit>
  );
};
