import { Loading, Show, useGetOne, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { HardwareShowContent } from './ui/content';

export const HardwareShow = (props: { title?: string }) => {
  const { id } = useParams();

  const redirect = useRedirect();
  const { data, isLoading } = useGetOne('hardwares', { id: id as string }, { onError: () => redirect('/hardwares') });

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;
  const version = data.version;

  return (
    <Show {...props} title={`${props.title} ${version}`}>
      <HardwareShowContent data={data} />
    </Show>
  );
};
