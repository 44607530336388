import { Menu, usePermissions } from 'react-admin';
import { Roles, Permissions } from 'types';
import { hasPermission } from '../../validators';
import { ResourceLabels, ResourceNames } from '../../types';

const menuItems = [
  { permission: Permissions.ReadAdmins, to: `/${ResourceNames.admins}`, primaryText: ResourceLabels.admins },
  { permission: Permissions.ReadUsers, to: `/${ResourceNames.users}`, primaryText: ResourceLabels.users },
  { permission: Permissions.ReadDevices, to: `/${ResourceNames.devices}`, primaryText: ResourceLabels.devices },
  { permission: Permissions.ReadFirmwares, to: `/${ResourceNames.firmwares}`, primaryText: ResourceLabels.firmwares },
  { permission: Permissions.ReadHardwares, to: `/${ResourceNames.hardwares}`, primaryText: ResourceLabels.hardwares },
  {
    permission: Permissions.ReadCartridges,
    to: `/${ResourceNames.cartridges}`,
    primaryText: ResourceLabels.cartridges,
  },
  { permission: Permissions.ReadModels, to: `/${ResourceNames.models}`, primaryText: ResourceLabels.models },
  {
    permission: Permissions.ReadOtaDevices,
    to: `/${ResourceNames.otaDevices}`,
    primaryText: ResourceLabels.otaDevices,
  },
  {
    permission: Permissions.ReadAgreements,
    to: `/${ResourceNames.flavorsTestAgreements}`,
    primaryText: ResourceLabels.flavorsTestAgreements,
  },
  {
    permission: Permissions.ReadAgreements,
    to: `/${ResourceNames.metaBetaAgreements}`,
    primaryText: ResourceLabels.metaBetaAgreements,
  },
  {
    permission: Permissions.ReadWarranties,
    to: `/${ResourceNames.warrantiesMetaLite}`,
    primaryText: ResourceLabels.warrantiesMetaLite,
  },
  {
    permission: Permissions.ReadWarranties,
    to: `/${ResourceNames.warrantiesMetaSmart}`,
    primaryText: ResourceLabels.warrantiesMetaSmart,
  },
  {
    permission: Permissions.ReadFeatureToggles,
    to: `/${ResourceNames.featureToggles}`,
    primaryText: ResourceLabels.featureToggles,
  },
];

export const SidebarMenu = () => {
  const { permissions } = usePermissions();
  const role = permissions as Roles;

  if (!role) return null;

  return (
    <Menu>
      {menuItems.map(
        item =>
          hasPermission(role, item.permission) && (
            <Menu.Item key={item.to} to={item.to} primaryText={item.primaryText} />
          ),
      )}
    </Menu>
  );
};
